import { CaseDTO, __, getApiRoutes } from "@adac/core-model";
import { useContext } from "react";

import StoreContext from '../stores';
import { request } from "@adac/core-view";

export function useWorkflowCallCustomerAction<Data>() {
  const { case: caseStore } = useContext(StoreContext);
  
  return async (data: Data) => {
    if (!caseStore.currentCompanyId) throw new Error(__('Company cannot be identified'));
    await request<CaseDTO, Data>(getApiRoutes().partner.case(caseStore.token).action.callCustomer(), {
      method: 'POST',  
      headers: {
        Authorization: `Bearer ${caseStore.accessToken}` 
      },
      data
    });
  }
};
