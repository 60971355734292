import React from 'react';
import { Security, RouterContextContainer } from '@adac/core-view';
import { LayoutContainer } from './components/layout';
import { useRedirectWithAccessToken, useRedirectWithCaseToken } from './hooks/useRedirect';

const App = () => {
  const redirectWithCaseToken = useRedirectWithCaseToken();
  const redirectWithAccessToken = useRedirectWithAccessToken();

  return (
    <Security>
      <RouterContextContainer
        redirectWithCaseToken={redirectWithCaseToken}
        redirectWithAccessToken={redirectWithAccessToken}
      >
        <LayoutContainer />
      </RouterContextContainer>
    </Security>
  );
};

export default App;
