/* eslint-disable no-return-await */
import {
  Page, SubPage, extractAddressLocationNameObject, useAsyncAction, useReportRequest, Spinner,
  request,
} from '@adac/core-view';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import {
  viewFinalReportStates, isValidEmail, AccuracyType, CaseReportDTO, base64ImageRegex,
} from '@adac/core-model';

import * as yup from 'yup';
import concatSchemas from '@adac/core-view/lib/helpers/yup-helper';
import {
  BillingAddressDataValues, BillingAddressNameSchema, emptyDefaultAddress,
} from './BillingAddressForm';
import FinalReportForm from './FinalReportForm';
import StoreContext from '../../stores';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import { useFinalReportSubmitHandler } from '../../hooks/useFinalReportSubmitHandler';
import { PhotoFromBackend } from '../../stores/DoorPhotoStore';

const photoSchema = yup.object({
  photos: yup.array().of(
    yup.object({
      image: yup.string().matches(base64ImageRegex).required(),
      text: yup.string(),
    }),
  ).required().min(1),
});

const finalReportValidationSchema = (baseSchema: yup.ObjectSchema<any>) => concatSchemas(photoSchema, baseSchema)?.concat(
  yup.object().shape({
    email: yup.string().when('receiveByEmail', {
      is: true,
      then: schema => schema.test('isValidEmail', 'Invalid email address', value => isValidEmail(value)).required('Email is required'),
      otherwise: schema => schema.nullable(),
    }),
    reason: yup.string().when('notAccepted', {
      is: true,
      then: schema => schema.min(10, 'Cancellation reason must be at least 10 characters').required('Cancellation reason is required'),
      otherwise: schema => schema.optional(),
    }),
    signatureData: yup.string().when('notAccepted', {
      is: false,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    notAccepted: yup.boolean(),
  }),
);

export interface FinalReportFormValues extends BillingAddressDataValues{
  receiveByEmail: boolean;
  email: string | undefined | null;
  notAccepted: boolean;
  reason: string;
  showAddress: boolean;
  signatureData: string;
  photos: PhotoFromBackend[];
}

const FinalReport = observer(() => {
  const {
    case: caseStore, afterOpeningDoorPhotos: doorStore,
  } = useContext(StoreContext);

  const { requestUrl, isView } = useReportRequest(caseStore.token, caseStore.currentCompanyId);

  const action = useAsyncAction(async () => (
    await request<CaseReportDTO>(requestUrl, {
      headers: {
        Authorization: `Bearer ${caseStore.accessToken}`,
      },
    })
  ), [requestUrl]);

  const { resource, isLoading } = action;

  const billingAddressFromServer = resource?.billingAddress;

  const isInsurance = useIsInsuranceProduct();
  const validationSchema = finalReportValidationSchema(isInsurance ? yup.object({}) : BillingAddressNameSchema);

  const initialValues = {
    ...(billingAddressFromServer && extractAddressLocationNameObject(billingAddressFromServer)) || emptyDefaultAddress,
    receiveByEmail: !!resource?.case?.customerEmail,
    email: resource?.case?.customerEmail,
    notAccepted: resource?.finalReport?.notAccepted || false,
    reason: '',
    showAddress: false,
    signatureData: '',
    confidence: 1,
    accuracyType: 'houseNumber' as AccuracyType,
    photos: doorStore.photosForPost,
  };

  const handleSubmit = useFinalReportSubmitHandler(billingAddressFromServer);

  if ((isView && !viewFinalReportStates.includes(caseStore.status)) || !resource) {
    return null;
  }

  if (isLoading) {
    return (
      <Page>
        <SubPage>
          <Spinner />
        </SubPage>
      </Page>
    );
  }

  return (
    <Page>
      <SubPage>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
          onSubmit={handleSubmit}
        >
          <FinalReportForm action={action} />
        </Formik>
      </SubPage>
    </Page>
  );
});

export default FinalReport;
