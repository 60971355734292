import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import {
  Page, Title, LightSubTitle, Text, FlexView, Button, ButtonListStyles, SubTitle, useLocation, Timer, useAsyncComponent,
  request,
} from '@adac/core-view';
import {
  // ZipsByCity,
  CaseActions, formatStopwatch, __, getApiRoutes, complexCaseAllowedCompanyTypes, TimeType, getClaimReference,
} from '@adac/core-model';

import StoreContext from '../../stores';
import { routeNames } from '../layout/OverlayRouterContainer';
import { useWorkflowDriverAction } from '../../hooks/useWorkflowDriverAction';

export const BigTimerRenderer = (time: TimeType) => (
  <Text fontSize="64px">
    {formatStopwatch(time)}
  </Text>
);

const TimerStyles = styled(FlexView)`
  background-color: ${props => props.theme.colors.backgroundLight};
  margin: 24px -16px;
`;

const PageStyle = styled(Page)`
  display: flex;
  background-color: white;
  min-height: 75vh;
  height: 100% !important;
`;

const DoorOpeningStarted = observer(() => {
  const { case: caseStore } = useContext(StoreContext);
  const { companyInfo, additionalCost } = caseStore;

  const { goTo, goToWithRootId } = useLocation();

  const onOpeningStarted = () => {
    goToWithRootId(routeNames.finalStatusReport('images'));
  };

  const editDamageAction = useWorkflowDriverAction(CaseActions.EDIT_EXTRA_DAMAGE);


  const onComissionExtraCost = async () => {
    if (additionalCost) {
      await editDamageAction({});
      goToWithRootId(routeNames.comissionAdditional());
    } else {
      goToWithRootId(routeNames.commissionExtraDamage('active'));
    }
  };

  const onOpeningFailed = () => {
    if (!caseStore.currentCompanyId) throw new Error(__('Company cannot be identified'));
    // eslint-disable-next-line no-alert
    if (window.confirm(__('Are you sure? This action will end the case.'))) {
      request(getApiRoutes().partner.case(caseStore.token).action.setStatus(CaseActions.GIVE_UP_COMISSION, caseStore.currentCompanyId), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${caseStore.accessToken}`,
        },
        data: {
          reason: 'InsufficientCapabilities',
        }
      });
      goTo(routeNames.openingFailed);
    }
  };

  const isFailureReopenAllowed = !complexCaseAllowedCompanyTypes.includes(companyInfo?.type);

  const { Component: ConfirmExtraCostButton } = useAsyncComponent({
    onClick: onComissionExtraCost,
  });

  return (
    <PageStyle>
      <FlexView column padding="16px">
        <Title>{__('In Arbeit')}</Title>
        <LightSubTitle>{__('Hermes Id')}: {getClaimReference(caseStore.commissioningReference)}</LightSubTitle>
        <TimerStyles column center>
          <Timer
            startTime={caseStore.getOpeningStartedTime}
            countdown={false}
            renderer={BigTimerRenderer}
          />
          <SubTitle>{__('Laufender Einsatz')}</SubTitle>
        </TimerStyles>

        <ButtonListStyles>
          {!isFailureReopenAllowed && <ConfirmExtraCostButton info title={__('Not possible without damage')} />}
          <Button cta title={__('Zum Tätigkeitsbericht')} onClick={onOpeningStarted} />
          {isFailureReopenAllowed && <Button small title={__('Opening failed')} onClick={onOpeningFailed} />}
        </ButtonListStyles>
      </FlexView>
    </PageStyle>
  );
});

export default DoorOpeningStarted;
